import { Outlet, NavLink, Link } from 'react-router-dom';

import styles from './Layout.module.css';

const Layout = () => {
  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <Link to="/" className={styles.headerTitleContainer}>
            <img src={'Feedyou_logo_white_clean.svg'} alt="Feedyou" className={styles.headerLogo} />
            <h3 className={styles.headerTitle}>AI Knowledge Base</h3>
          </Link>
          <nav>
            <ul className={styles.headerNavList}>
              {/*<li>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                >
                  Chat
                </NavLink>
              </li>
              <li className={styles.headerNavLeftMargin}>
                <NavLink
                  to="/qa"
                  className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                >
                  Ask a question
                </NavLink>
              </li>*/}
            </ul>
          </nav>
        </div>
      </header>

      <Outlet />
    </div>
  );
};

export default Layout;
